export function QrIcon() {
  return (
    <svg
      id="Group_275"
      data-name="Group 275"
      xmlns="http://www.w3.org/2000/svg"
      width="69.579"
      height="69.579"
      viewBox="0 0 69.579 69.579"
    >
      <rect
        id="Rectangle_1064"
        data-name="Rectangle 1064"
        width="2.864"
        height="2.864"
        transform="translate(25.018 2.78)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1065"
        data-name="Rectangle 1065"
        width="2.864"
        height="2.864"
        transform="translate(27.798 2.78)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1066"
        data-name="Rectangle 1066"
        width="2.864"
        height="2.864"
        transform="translate(33.358 2.78)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1067"
        data-name="Rectangle 1067"
        width="2.864"
        height="2.864"
        transform="translate(41.697 2.78)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1068"
        data-name="Rectangle 1068"
        width="2.864"
        height="2.864"
        transform="translate(44.477 2.78)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1069"
        data-name="Rectangle 1069"
        width="2.864"
        height="2.864"
        transform="translate(22.238 5.56)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1070"
        data-name="Rectangle 1070"
        width="2.864"
        height="2.864"
        transform="translate(25.018 5.56)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1071"
        data-name="Rectangle 1071"
        width="2.864"
        height="2.864"
        transform="translate(41.697 5.56)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1072"
        data-name="Rectangle 1072"
        width="2.864"
        height="2.864"
        transform="translate(44.477 5.56)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1073"
        data-name="Rectangle 1073"
        width="2.864"
        height="2.864"
        transform="translate(22.238 8.34)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1074"
        data-name="Rectangle 1074"
        width="2.864"
        height="2.864"
        transform="translate(30.578 8.34)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1075"
        data-name="Rectangle 1075"
        width="2.864"
        height="2.864"
        transform="translate(36.138 8.34)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1076"
        data-name="Rectangle 1076"
        width="2.864"
        height="2.864"
        transform="translate(38.917 8.34)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1077"
        data-name="Rectangle 1077"
        width="2.864"
        height="2.864"
        transform="translate(41.697 8.34)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1078"
        data-name="Rectangle 1078"
        width="2.864"
        height="2.864"
        transform="translate(22.238 11.119)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1079"
        data-name="Rectangle 1079"
        width="2.864"
        height="2.864"
        transform="translate(30.578 11.119)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1080"
        data-name="Rectangle 1080"
        width="2.864"
        height="2.864"
        transform="translate(38.917 11.119)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1081"
        data-name="Rectangle 1081"
        width="2.864"
        height="2.864"
        transform="translate(44.477 11.119)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1082"
        data-name="Rectangle 1082"
        width="2.864"
        height="2.864"
        transform="translate(22.238 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1083"
        data-name="Rectangle 1083"
        width="2.864"
        height="2.864"
        transform="translate(25.018 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1084"
        data-name="Rectangle 1084"
        width="2.864"
        height="2.864"
        transform="translate(27.798 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1085"
        data-name="Rectangle 1085"
        width="2.864"
        height="2.864"
        transform="translate(30.578 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1086"
        data-name="Rectangle 1086"
        width="2.864"
        height="2.864"
        transform="translate(33.358 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1087"
        data-name="Rectangle 1087"
        width="2.864"
        height="2.864"
        transform="translate(41.697 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1088"
        data-name="Rectangle 1088"
        width="2.864"
        height="2.864"
        transform="translate(44.477 13.899)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1089"
        data-name="Rectangle 1089"
        width="2.864"
        height="2.864"
        transform="translate(22.238 16.679)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1090"
        data-name="Rectangle 1090"
        width="2.864"
        height="2.864"
        transform="translate(27.798 16.679)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1091"
        data-name="Rectangle 1091"
        width="2.864"
        height="2.864"
        transform="translate(33.358 16.679)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1092"
        data-name="Rectangle 1092"
        width="2.864"
        height="2.864"
        transform="translate(38.917 16.679)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1093"
        data-name="Rectangle 1093"
        width="2.864"
        height="2.864"
        transform="translate(44.477 16.679)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1094"
        data-name="Rectangle 1094"
        width="2.864"
        height="2.864"
        transform="translate(22.238 19.459)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1095"
        data-name="Rectangle 1095"
        width="2.864"
        height="2.864"
        transform="translate(25.018 19.459)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1096"
        data-name="Rectangle 1096"
        width="2.864"
        height="2.864"
        transform="translate(30.578 19.459)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1097"
        data-name="Rectangle 1097"
        width="2.864"
        height="2.864"
        transform="translate(44.477 19.459)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1098"
        data-name="Rectangle 1098"
        width="2.864"
        height="2.864"
        transform="translate(0 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1099"
        data-name="Rectangle 1099"
        width="2.864"
        height="2.864"
        transform="translate(5.56 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1100"
        data-name="Rectangle 1100"
        width="2.864"
        height="2.864"
        transform="translate(8.34 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1101"
        data-name="Rectangle 1101"
        width="2.864"
        height="2.864"
        transform="translate(11.12 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1102"
        data-name="Rectangle 1102"
        width="2.864"
        height="2.864"
        transform="translate(13.898 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1103"
        data-name="Rectangle 1103"
        width="2.864"
        height="2.864"
        transform="translate(16.678 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1104"
        data-name="Rectangle 1104"
        width="2.864"
        height="2.864"
        transform="translate(27.798 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1105"
        data-name="Rectangle 1105"
        width="2.864"
        height="2.864"
        transform="translate(36.138 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1106"
        data-name="Rectangle 1106"
        width="2.864"
        height="2.864"
        transform="translate(38.917 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1107"
        data-name="Rectangle 1107"
        width="2.864"
        height="2.864"
        transform="translate(50.037 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1108"
        data-name="Rectangle 1108"
        width="2.864"
        height="2.864"
        transform="translate(52.817 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1109"
        data-name="Rectangle 1109"
        width="2.864"
        height="2.864"
        transform="translate(55.597 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1110"
        data-name="Rectangle 1110"
        width="2.864"
        height="2.864"
        transform="translate(58.377 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1111"
        data-name="Rectangle 1111"
        width="2.864"
        height="2.864"
        transform="translate(61.157 22.239)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1112"
        data-name="Rectangle 1112"
        width="2.864"
        height="2.864"
        transform="translate(0 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1113"
        data-name="Rectangle 1113"
        width="2.864"
        height="2.864"
        transform="translate(2.78 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1114"
        data-name="Rectangle 1114"
        width="2.864"
        height="2.864"
        transform="translate(5.56 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1115"
        data-name="Rectangle 1115"
        width="2.864"
        height="2.864"
        transform="translate(11.12 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1116"
        data-name="Rectangle 1116"
        width="2.864"
        height="2.864"
        transform="translate(13.898 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1117"
        data-name="Rectangle 1117"
        width="2.864"
        height="2.864"
        transform="translate(19.458 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1118"
        data-name="Rectangle 1118"
        width="2.864"
        height="2.864"
        transform="translate(22.238 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1119"
        data-name="Rectangle 1119"
        width="2.864"
        height="2.864"
        transform="translate(27.798 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1120"
        data-name="Rectangle 1120"
        width="2.864"
        height="2.864"
        transform="translate(38.917 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1121"
        data-name="Rectangle 1121"
        width="2.864"
        height="2.864"
        transform="translate(44.477 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1122"
        data-name="Rectangle 1122"
        width="2.864"
        height="2.864"
        transform="translate(47.257 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1123"
        data-name="Rectangle 1123"
        width="2.864"
        height="2.864"
        transform="translate(52.817 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1124"
        data-name="Rectangle 1124"
        width="2.864"
        height="2.864"
        transform="translate(63.935 25.019)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1125"
        data-name="Rectangle 1125"
        width="2.864"
        height="2.864"
        transform="translate(0 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1126"
        data-name="Rectangle 1126"
        width="2.864"
        height="2.864"
        transform="translate(5.56 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1127"
        data-name="Rectangle 1127"
        width="2.864"
        height="2.864"
        transform="translate(8.34 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1128"
        data-name="Rectangle 1128"
        width="2.864"
        height="2.864"
        transform="translate(16.678 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1129"
        data-name="Rectangle 1129"
        width="2.864"
        height="2.864"
        transform="translate(30.578 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1130"
        data-name="Rectangle 1130"
        width="2.864"
        height="2.864"
        transform="translate(36.138 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1131"
        data-name="Rectangle 1131"
        width="2.864"
        height="2.864"
        transform="translate(41.697 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1132"
        data-name="Rectangle 1132"
        width="2.864"
        height="2.864"
        transform="translate(47.257 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1133"
        data-name="Rectangle 1133"
        width="2.864"
        height="2.864"
        transform="translate(50.037 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1134"
        data-name="Rectangle 1134"
        width="2.864"
        height="2.864"
        transform="translate(52.817 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1135"
        data-name="Rectangle 1135"
        width="2.864"
        height="2.864"
        transform="translate(55.597 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1136"
        data-name="Rectangle 1136"
        width="2.864"
        height="2.864"
        transform="translate(58.377 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1137"
        data-name="Rectangle 1137"
        width="2.864"
        height="2.864"
        transform="translate(63.935 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1138"
        data-name="Rectangle 1138"
        width="2.864"
        height="2.864"
        transform="translate(66.715 27.799)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1139"
        data-name="Rectangle 1139"
        width="2.864"
        height="2.864"
        transform="translate(0 30.577)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1140"
        data-name="Rectangle 1140"
        width="2.864"
        height="2.864"
        transform="translate(8.34 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1141"
        data-name="Rectangle 1141"
        width="2.864"
        height="2.864"
        transform="translate(11.12 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1142"
        data-name="Rectangle 1142"
        width="2.864"
        height="2.864"
        transform="translate(13.898 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1143"
        data-name="Rectangle 1143"
        width="2.864"
        height="2.864"
        transform="translate(19.458 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1144"
        data-name="Rectangle 1144"
        width="2.864"
        height="2.864"
        transform="translate(22.238 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1145"
        data-name="Rectangle 1145"
        width="2.864"
        height="2.864"
        transform="translate(27.798 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1146"
        data-name="Rectangle 1146"
        width="2.864"
        height="2.864"
        transform="translate(30.578 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1147"
        data-name="Rectangle 1147"
        width="2.864"
        height="2.864"
        transform="translate(33.358 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1148"
        data-name="Rectangle 1148"
        width="2.864"
        height="2.864"
        transform="translate(47.257 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1149"
        data-name="Rectangle 1149"
        width="2.864"
        height="2.864"
        transform="translate(52.817 30.579)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1150"
        data-name="Rectangle 1150"
        width="2.864"
        height="2.864"
        transform="translate(66.715 30.577)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1151"
        data-name="Rectangle 1151"
        width="2.864"
        height="2.864"
        transform="translate(0 33.357)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1152"
        data-name="Rectangle 1152"
        width="2.864"
        height="2.864"
        transform="translate(5.56 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1153"
        data-name="Rectangle 1153"
        width="2.864"
        height="2.864"
        transform="translate(8.34 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1154"
        data-name="Rectangle 1154"
        width="2.864"
        height="2.864"
        transform="translate(11.12 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1155"
        data-name="Rectangle 1155"
        width="2.864"
        height="2.864"
        transform="translate(16.678 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1156"
        data-name="Rectangle 1156"
        width="2.864"
        height="2.864"
        transform="translate(22.238 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1157"
        data-name="Rectangle 1157"
        width="2.864"
        height="2.864"
        transform="translate(33.358 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1158"
        data-name="Rectangle 1158"
        width="2.864"
        height="2.864"
        transform="translate(36.138 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1159"
        data-name="Rectangle 1159"
        width="2.864"
        height="2.864"
        transform="translate(38.917 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1160"
        data-name="Rectangle 1160"
        width="2.864"
        height="2.864"
        transform="translate(41.697 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1161"
        data-name="Rectangle 1161"
        width="2.864"
        height="2.864"
        transform="translate(44.477 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1162"
        data-name="Rectangle 1162"
        width="2.864"
        height="2.864"
        transform="translate(47.257 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1163"
        data-name="Rectangle 1163"
        width="2.864"
        height="2.864"
        transform="translate(50.037 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1164"
        data-name="Rectangle 1164"
        width="2.864"
        height="2.864"
        transform="translate(52.817 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1165"
        data-name="Rectangle 1165"
        width="2.864"
        height="2.864"
        transform="translate(55.597 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1166"
        data-name="Rectangle 1166"
        width="2.864"
        height="2.864"
        transform="translate(61.157 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1167"
        data-name="Rectangle 1167"
        width="2.864"
        height="2.864"
        transform="translate(63.935 33.359)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1168"
        data-name="Rectangle 1168"
        width="2.864"
        height="2.864"
        transform="translate(66.715 33.357)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1169"
        data-name="Rectangle 1169"
        width="2.864"
        height="2.864"
        transform="translate(0 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1170"
        data-name="Rectangle 1170"
        width="2.864"
        height="2.864"
        transform="translate(5.56 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1171"
        data-name="Rectangle 1171"
        width="2.864"
        height="2.864"
        transform="translate(22.238 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1172"
        data-name="Rectangle 1172"
        width="2.864"
        height="2.864"
        transform="translate(25.018 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1173"
        data-name="Rectangle 1173"
        width="2.864"
        height="2.864"
        transform="translate(27.798 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1174"
        data-name="Rectangle 1174"
        width="2.864"
        height="2.864"
        transform="translate(33.358 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1175"
        data-name="Rectangle 1175"
        width="2.864"
        height="2.864"
        transform="translate(44.477 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1176"
        data-name="Rectangle 1176"
        width="2.864"
        height="2.864"
        transform="translate(47.257 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1177"
        data-name="Rectangle 1177"
        width="2.864"
        height="2.864"
        transform="translate(52.817 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1178"
        data-name="Rectangle 1178"
        width="2.864"
        height="2.864"
        transform="translate(58.377 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1179"
        data-name="Rectangle 1179"
        width="2.864"
        height="2.864"
        transform="translate(63.935 36.137)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1180"
        data-name="Rectangle 1180"
        width="2.864"
        height="2.864"
        transform="translate(0 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1181"
        data-name="Rectangle 1181"
        width="2.864"
        height="2.864"
        transform="translate(5.56 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1182"
        data-name="Rectangle 1182"
        width="2.864"
        height="2.864"
        transform="translate(8.34 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1183"
        data-name="Rectangle 1183"
        width="2.864"
        height="2.864"
        transform="translate(16.678 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1184"
        data-name="Rectangle 1184"
        width="2.864"
        height="2.864"
        transform="translate(22.238 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1185"
        data-name="Rectangle 1185"
        width="2.864"
        height="2.864"
        transform="translate(27.798 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1186"
        data-name="Rectangle 1186"
        width="2.864"
        height="2.864"
        transform="translate(38.917 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1187"
        data-name="Rectangle 1187"
        width="2.864"
        height="2.864"
        transform="translate(41.697 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1188"
        data-name="Rectangle 1188"
        width="2.864"
        height="2.864"
        transform="translate(44.477 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1189"
        data-name="Rectangle 1189"
        width="2.864"
        height="2.864"
        transform="translate(47.257 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1190"
        data-name="Rectangle 1190"
        width="2.864"
        height="2.864"
        transform="translate(50.037 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1191"
        data-name="Rectangle 1191"
        width="2.864"
        height="2.864"
        transform="translate(52.817 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1192"
        data-name="Rectangle 1192"
        width="2.864"
        height="2.864"
        transform="translate(55.597 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1193"
        data-name="Rectangle 1193"
        width="2.864"
        height="2.864"
        transform="translate(58.377 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1194"
        data-name="Rectangle 1194"
        width="2.864"
        height="2.864"
        transform="translate(63.935 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1195"
        data-name="Rectangle 1195"
        width="2.864"
        height="2.864"
        transform="translate(66.715 38.917)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1196"
        data-name="Rectangle 1196"
        width="2.864"
        height="2.864"
        transform="translate(0 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1197"
        data-name="Rectangle 1197"
        width="2.864"
        height="2.864"
        transform="translate(5.56 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1198"
        data-name="Rectangle 1198"
        width="2.864"
        height="2.864"
        transform="translate(8.34 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1199"
        data-name="Rectangle 1199"
        width="2.864"
        height="2.864"
        transform="translate(11.12 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1200"
        data-name="Rectangle 1200"
        width="2.864"
        height="2.864"
        transform="translate(13.898 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1201"
        data-name="Rectangle 1201"
        width="2.864"
        height="2.864"
        transform="translate(19.458 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1202"
        data-name="Rectangle 1202"
        width="2.864"
        height="2.864"
        transform="translate(22.238 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1203"
        data-name="Rectangle 1203"
        width="2.864"
        height="2.864"
        transform="translate(33.358 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1204"
        data-name="Rectangle 1204"
        width="2.864"
        height="2.864"
        transform="translate(38.917 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1205"
        data-name="Rectangle 1205"
        width="2.864"
        height="2.864"
        transform="translate(41.697 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1206"
        data-name="Rectangle 1206"
        width="2.864"
        height="2.864"
        transform="translate(44.477 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1207"
        data-name="Rectangle 1207"
        width="2.864"
        height="2.864"
        transform="translate(47.257 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1208"
        data-name="Rectangle 1208"
        width="2.864"
        height="2.864"
        transform="translate(52.817 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1209"
        data-name="Rectangle 1209"
        width="2.864"
        height="2.864"
        transform="translate(55.597 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1210"
        data-name="Rectangle 1210"
        width="2.864"
        height="2.864"
        transform="translate(66.715 41.697)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1211"
        data-name="Rectangle 1211"
        width="2.864"
        height="2.864"
        transform="translate(0 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1212"
        data-name="Rectangle 1212"
        width="2.864"
        height="2.864"
        transform="translate(5.56 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1213"
        data-name="Rectangle 1213"
        width="2.864"
        height="2.864"
        transform="translate(16.678 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1214"
        data-name="Rectangle 1214"
        width="2.864"
        height="2.864"
        transform="translate(19.458 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1215"
        data-name="Rectangle 1215"
        width="2.864"
        height="2.864"
        transform="translate(22.238 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1216"
        data-name="Rectangle 1216"
        width="2.864"
        height="2.864"
        transform="translate(25.018 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1217"
        data-name="Rectangle 1217"
        width="2.864"
        height="2.864"
        transform="translate(27.798 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1218"
        data-name="Rectangle 1218"
        width="2.864"
        height="2.864"
        transform="translate(33.358 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1219"
        data-name="Rectangle 1219"
        width="2.864"
        height="2.864"
        transform="translate(36.138 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1220"
        data-name="Rectangle 1220"
        width="2.864"
        height="2.864"
        transform="translate(38.917 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1221"
        data-name="Rectangle 1221"
        width="2.864"
        height="2.864"
        transform="translate(41.697 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1222"
        data-name="Rectangle 1222"
        width="2.864"
        height="2.864"
        transform="translate(44.477 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1223"
        data-name="Rectangle 1223"
        width="2.864"
        height="2.864"
        transform="translate(47.257 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1224"
        data-name="Rectangle 1224"
        width="2.864"
        height="2.864"
        transform="translate(50.037 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1225"
        data-name="Rectangle 1225"
        width="2.864"
        height="2.864"
        transform="translate(52.817 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1226"
        data-name="Rectangle 1226"
        width="2.864"
        height="2.864"
        transform="translate(55.597 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1227"
        data-name="Rectangle 1227"
        width="2.864"
        height="2.864"
        transform="translate(61.157 44.477)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1228"
        data-name="Rectangle 1228"
        width="2.864"
        height="2.864"
        transform="translate(22.238 47.257)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1229"
        data-name="Rectangle 1229"
        width="2.864"
        height="2.864"
        transform="translate(30.578 47.257)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1230"
        data-name="Rectangle 1230"
        width="2.864"
        height="2.864"
        transform="translate(38.917 47.257)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1231"
        data-name="Rectangle 1231"
        width="2.864"
        height="2.864"
        transform="translate(44.477 47.257)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1232"
        data-name="Rectangle 1232"
        width="2.864"
        height="2.864"
        transform="translate(55.597 47.257)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1233"
        data-name="Rectangle 1233"
        width="2.864"
        height="2.864"
        transform="translate(58.377 47.257)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1234"
        data-name="Rectangle 1234"
        width="2.864"
        height="2.864"
        transform="translate(25.018 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1235"
        data-name="Rectangle 1235"
        width="2.864"
        height="2.864"
        transform="translate(33.358 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1236"
        data-name="Rectangle 1236"
        width="2.864"
        height="2.864"
        transform="translate(36.138 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1237"
        data-name="Rectangle 1237"
        width="2.864"
        height="2.864"
        transform="translate(44.477 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1238"
        data-name="Rectangle 1238"
        width="2.864"
        height="2.864"
        transform="translate(50.037 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1239"
        data-name="Rectangle 1239"
        width="2.864"
        height="2.864"
        transform="translate(55.597 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1240"
        data-name="Rectangle 1240"
        width="2.864"
        height="2.864"
        transform="translate(61.157 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1241"
        data-name="Rectangle 1241"
        width="2.864"
        height="2.864"
        transform="translate(63.935 50.037)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1242"
        data-name="Rectangle 1242"
        width="2.864"
        height="2.864"
        transform="translate(66.715 50.035)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1243"
        data-name="Rectangle 1243"
        width="2.864"
        height="2.864"
        transform="translate(22.238 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1244"
        data-name="Rectangle 1244"
        width="2.864"
        height="2.864"
        transform="translate(25.018 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1245"
        data-name="Rectangle 1245"
        width="2.864"
        height="2.864"
        transform="translate(27.798 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1246"
        data-name="Rectangle 1246"
        width="2.864"
        height="2.864"
        transform="translate(30.578 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1247"
        data-name="Rectangle 1247"
        width="2.864"
        height="2.864"
        transform="translate(33.358 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1248"
        data-name="Rectangle 1248"
        width="2.864"
        height="2.864"
        transform="translate(38.917 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1249"
        data-name="Rectangle 1249"
        width="2.864"
        height="2.864"
        transform="translate(44.477 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1250"
        data-name="Rectangle 1250"
        width="2.864"
        height="2.864"
        transform="translate(55.597 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1251"
        data-name="Rectangle 1251"
        width="2.864"
        height="2.864"
        transform="translate(58.377 52.817)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1252"
        data-name="Rectangle 1252"
        width="2.864"
        height="2.864"
        transform="translate(22.238 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1253"
        data-name="Rectangle 1253"
        width="2.864"
        height="2.864"
        transform="translate(33.358 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1254"
        data-name="Rectangle 1254"
        width="2.864"
        height="2.864"
        transform="translate(36.138 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1255"
        data-name="Rectangle 1255"
        width="2.864"
        height="2.864"
        transform="translate(38.917 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1256"
        data-name="Rectangle 1256"
        width="2.864"
        height="2.864"
        transform="translate(41.697 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1257"
        data-name="Rectangle 1257"
        width="2.864"
        height="2.864"
        transform="translate(44.477 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1258"
        data-name="Rectangle 1258"
        width="2.864"
        height="2.864"
        transform="translate(47.257 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1259"
        data-name="Rectangle 1259"
        width="2.864"
        height="2.864"
        transform="translate(50.037 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1260"
        data-name="Rectangle 1260"
        width="2.864"
        height="2.864"
        transform="translate(52.817 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1261"
        data-name="Rectangle 1261"
        width="2.864"
        height="2.864"
        transform="translate(55.597 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1262"
        data-name="Rectangle 1262"
        width="2.864"
        height="2.864"
        transform="translate(61.157 55.597)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1263"
        data-name="Rectangle 1263"
        width="2.864"
        height="2.864"
        transform="translate(22.238 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1264"
        data-name="Rectangle 1264"
        width="2.864"
        height="2.864"
        transform="translate(27.798 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1265"
        data-name="Rectangle 1265"
        width="2.864"
        height="2.864"
        transform="translate(30.578 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1266"
        data-name="Rectangle 1266"
        width="2.864"
        height="2.864"
        transform="translate(38.917 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1267"
        data-name="Rectangle 1267"
        width="2.864"
        height="2.864"
        transform="translate(50.037 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1268"
        data-name="Rectangle 1268"
        width="2.864"
        height="2.864"
        transform="translate(55.597 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1269"
        data-name="Rectangle 1269"
        width="2.864"
        height="2.864"
        transform="translate(58.377 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1270"
        data-name="Rectangle 1270"
        width="2.864"
        height="2.864"
        transform="translate(61.157 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1271"
        data-name="Rectangle 1271"
        width="2.864"
        height="2.864"
        transform="translate(63.935 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1272"
        data-name="Rectangle 1272"
        width="2.864"
        height="2.864"
        transform="translate(66.715 58.377)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1273"
        data-name="Rectangle 1273"
        width="2.864"
        height="2.864"
        transform="translate(22.238 61.155)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1274"
        data-name="Rectangle 1274"
        width="2.864"
        height="2.864"
        transform="translate(33.358 61.155)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1275"
        data-name="Rectangle 1275"
        width="2.864"
        height="2.864"
        transform="translate(36.138 61.155)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1276"
        data-name="Rectangle 1276"
        width="2.864"
        height="2.864"
        transform="translate(58.377 61.155)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1277"
        data-name="Rectangle 1277"
        width="2.864"
        height="2.864"
        transform="translate(61.157 61.155)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1278"
        data-name="Rectangle 1278"
        width="2.864"
        height="2.864"
        transform="translate(66.715 61.155)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1279"
        data-name="Rectangle 1279"
        width="2.864"
        height="2.864"
        transform="translate(25.018 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1280"
        data-name="Rectangle 1280"
        width="2.864"
        height="2.864"
        transform="translate(30.578 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1281"
        data-name="Rectangle 1281"
        width="2.864"
        height="2.864"
        transform="translate(38.917 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1282"
        data-name="Rectangle 1282"
        width="2.864"
        height="2.864"
        transform="translate(41.697 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1283"
        data-name="Rectangle 1283"
        width="2.864"
        height="2.864"
        transform="translate(44.477 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1284"
        data-name="Rectangle 1284"
        width="2.864"
        height="2.864"
        transform="translate(47.257 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1285"
        data-name="Rectangle 1285"
        width="2.864"
        height="2.864"
        transform="translate(52.817 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1286"
        data-name="Rectangle 1286"
        width="2.864"
        height="2.864"
        transform="translate(55.597 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1287"
        data-name="Rectangle 1287"
        width="2.864"
        height="2.864"
        transform="translate(58.377 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1288"
        data-name="Rectangle 1288"
        width="2.864"
        height="2.864"
        transform="translate(66.715 63.935)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1289"
        data-name="Rectangle 1289"
        width="2.864"
        height="2.864"
        transform="translate(22.238 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1290"
        data-name="Rectangle 1290"
        width="2.864"
        height="2.864"
        transform="translate(30.578 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1291"
        data-name="Rectangle 1291"
        width="2.864"
        height="2.864"
        transform="translate(33.358 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1292"
        data-name="Rectangle 1292"
        width="2.864"
        height="2.864"
        transform="translate(36.138 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1293"
        data-name="Rectangle 1293"
        width="2.864"
        height="2.864"
        transform="translate(52.817 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1294"
        data-name="Rectangle 1294"
        width="2.864"
        height="2.864"
        transform="translate(55.597 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1295"
        data-name="Rectangle 1295"
        width="2.864"
        height="2.864"
        transform="translate(58.377 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1296"
        data-name="Rectangle 1296"
        width="2.864"
        height="2.864"
        transform="translate(61.155 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1297"
        data-name="Rectangle 1297"
        width="2.864"
        height="2.864"
        transform="translate(63.937 66.715)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1298"
        data-name="Rectangle 1298"
        width="2.864"
        height="2.864"
        transform="translate(66.715 66.715)"
        fill="#141a21"
      />
      <path
        id="Path_1374"
        data-name="Path 1374"
        d="M2.78,2.78h13.9v13.9H2.78ZM0,19.458H19.458V0H0Z"
        fill="#141a21"
      />
      <path
        id="Path_1375"
        data-name="Path 1375"
        d="M33.324,2.78h13.9v13.9h-13.9Zm-2.78,16.678H50V0H30.544Z"
        transform="translate(19.493)"
        fill="#141a21"
      />
      <path
        id="Path_1376"
        data-name="Path 1376"
        d="M2.78,33.324h13.9v13.9H2.78ZM0,50H19.458V30.544H0Z"
        transform="translate(0 19.493)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1299"
        data-name="Rectangle 1299"
        width="8.34"
        height="8.34"
        transform="translate(5.56 5.56)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1300"
        data-name="Rectangle 1300"
        width="8.34"
        height="8.34"
        transform="translate(55.597 5.56)"
        fill="#141a21"
      />
      <rect
        id="Rectangle_1301"
        data-name="Rectangle 1301"
        width="8.34"
        height="8.34"
        transform="translate(5.56 55.597)"
        fill="#141a21"
      />
    </svg>
  );
}
