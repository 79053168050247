export function Minus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="4.375"
      viewBox="0 0 35 4.375"
    >
      <g id="minus" transform="translate(0 -224)">
        <path
          id="Path_1384"
          data-name="Path 1384"
          d="M32.813,228.375H2.188a2.188,2.188,0,0,1,0-4.375H32.813a2.188,2.188,0,0,1,0,4.375Z"
        />
      </g>
    </svg>
  );
}
