import React from "react";
import ScrollSpy from "react-ui-scrollspy";
import ScrollToTop from "react-scroll-to-top";
import { NavbarMenu } from "../../components/header/navbar";
import { Header } from "../../components/header";
import { Video } from "../../components/video";
import { GetPacket } from "../../components/get-packet";
import { WidthPacket } from "../../components/width-packet";
import { Footer } from "../../components/footer";
import { ArrowUp } from "../../assets/arrow-up";
import { Location } from "../../components/location";

function Home() {
  return (
    <ScrollSpy scrollThrottle={100} useBoxMethod={false}>
      <NavbarMenu />

      <Header />
      <Video />
      <GetPacket />
      <WidthPacket />
      <Location />
      <Footer />
      <ScrollToTop smooth={true} color={"#155b98"} component={<ArrowUp />} />
    </ScrollSpy>
  );
}

export default Home;
