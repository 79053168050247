export function TimeSpent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48.404"
      height="48.404"
      viewBox="0 0 48.404 48.404"
    >
      <g id="time-fast" transform="translate(0 0.007)">
        <path
          id="Path_1377"
          data-name="Path 1377"
          d="M18.151,26.034H2.017a2.017,2.017,0,1,1,0-4.034H18.151a2.017,2.017,0,0,1,0,4.034Z"
          transform="translate(0 22.363)"
        />
        <path
          id="Path_1378"
          data-name="Path 1378"
          d="M14.118,22.034H2.017a2.017,2.017,0,1,1,0-4.034h12.1a2.017,2.017,0,0,1,0,4.034Z"
          transform="translate(0 18.299)"
        />
        <path
          id="Path_1379"
          data-name="Path 1379"
          d="M10.084,18.034H2.017a2.017,2.017,0,1,1,0-4.034h8.067a2.017,2.017,0,1,1,0,4.034Z"
          transform="translate(0 14.234)"
        />
        <path
          id="Path_1380"
          data-name="Path 1380"
          d="M26.173,48.306a2.018,2.018,0,1,1-.179-4.033A20.163,20.163,0,1,0,4.071,22.367,2.017,2.017,0,0,1,.053,22,24.2,24.2,0,1,1,26.355,48.3C26.294,48.3,26.232,48.306,26.173,48.306Z"
          transform="translate(0.046 0)"
        />
        <path
          id="Path_1381"
          data-name="Path 1381"
          d="M13.017,6A2.017,2.017,0,0,0,11,8.017V18.1a2.017,2.017,0,0,0,.591,1.426l6.051,6.051a2.017,2.017,0,1,0,2.852-2.852l-5.46-5.459V8.017A2.017,2.017,0,0,0,13.017,6Z"
          transform="translate(11.185 6.102)"
        />
      </g>
    </svg>
  );
}
