export function ArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.625"
      height="25.5"
      viewBox="0 0 16.625 30.5"
    >
      <path
        id="arrow-down"
        d="M21.287,22.406a1.259,1.259,0,0,0-1.788,0l-4.572,4.572V1.259A1.259,1.259,0,0,0,13.668,0h0a1.259,1.259,0,0,0-1.259,1.259V26.965L7.849,22.406a1.259,1.259,0,1,0-1.738,1.776l4.937,4.937a3.778,3.778,0,0,0,5.34,0l4.937-4.937A1.259,1.259,0,0,0,21.287,22.406Z"
        transform="matrix(-1, 0.017, -0.017, -1, 22.197, 30.122)"
        fill="#155b98"
      />
    </svg>
  );
}
