import { NavbarMenu } from "../../components/header/navbar";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import ScrollToTop from "react-scroll-to-top";
import { ArrowUp } from "../../assets/arrow-up";
import ScrollSpy from "react-ui-scrollspy";
import React, { useEffect, useState } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { Viewer, Worker } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import style from "./TermsOfUse.module.scss";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();
function TermsOfUsePage() {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [file, setFile] = useState<string>("");
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    setFile("uslovi.pdf");
  }, []);
  return (
    <ScrollSpy scrollThrottle={100} useBoxMethod={false}>
      <NavbarMenu mustHref={true} />
      <Header />
      <div className={style.headerCc}>
        <div className={style.packetWidthContainer}>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        </div>
      </div>
      <Footer mustHref={true} />
      <ScrollToTop smooth={true} color={"#155b98"} component={<ArrowUp />} />
    </ScrollSpy>
  );
}

export default TermsOfUsePage;
