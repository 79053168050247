import style from "./GetPacket.module.scss";
import { Col, Row } from "reactstrap";
import { FastIcon } from "../../assets/fastIcon";
import { SecureIcon } from "../../assets/SecureIcon";
import { Secure2Icon } from "../../assets/Secure2Icon";
import { ButtonEE } from "../button";
import { QrIcon } from "../../assets/qr";
import { PhoneNumber } from "../../assets/phoneNumber";
import { PacketEE } from "../../assets/packetEE";
import { TimeSpent } from "../../assets/timeSpent";

export function GetPacket() {
  return (
    <div className={style.headerCc} id="steps">
      <div className={style.packetContainer}>
        <Col xl={12} lg={12} xs={12} md={12}>
          <div
            className={"w-100 d-flex align-items-center justify-content-center"}
          >
            <h2 className={style.packetTitle}>
              <span>ČETIRI KORAKA </span> do preuzimanja pošiljke
            </h2>
          </div>
        </Col>
        <Col xl={12} lg={12} xs={12} md={12} className={style.packetDetail}>
          <Row className={"justify-content-center"}>
            <Col
              xl={3}
              lg={4}
              md={9}
              xs={12}
              className={"pb-4 pb-xl-0 pb-lg-0"}
            >
              <div className={style.packetImage}></div>
            </Col>
            <Col xl={7} lg={7} md={12} xs={12} className={"ps-xl-5 ps-lg-5"}>
              <div className={"d-flex flex-column w-100"}>
                <Col xl={12} lg={12} xs={12} md={12}>
                  <div className={style.stepCol}>
                    <Col xl={2} lg={2} xs={3} md={2}>
                      <span className={style.step}>01</span>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      xs={9}
                      md={10}
                      className={"ps-3 ps-xl-0 ps-lg-0"}
                    >
                      <div
                        className={
                          "d-flex flex-wrap justify-content-between align-items-center "
                        }
                      >
                        <Col xl={6} lg={6} xs={12} md={12}>
                          <p className={style.stepTitle}>
                            Ukoliko ste odabrali dostavu putem paketomata i
                            dobili obavještenje da je vaš paket u paketomatu
                            pritisnite taster ‘’<span>Preuzmi paket</span>’’.{" "}
                          </p>
                        </Col>
                        <Col
                          xl={4}
                          lg={4}
                          xs={12}
                          md={12}
                          className={"pt-xl-0 pt-lg-0 pt-3"}
                        >
                          <div className={"d-flex justify-content-center"}>
                            <ButtonEE>
                              <p className={style.setButtonTextBtn}>
                                Preuzmi paket
                              </p>
                            </ButtonEE>
                          </div>
                        </Col>
                      </div>{" "}
                    </Col>
                  </div>
                </Col>
                <Col xl={12} lg={12} xs={12} md={12} className={"pt-4"}>
                  <div className={style.stepCol}>
                    <Col xl={2} lg={2} xs={3} md={2}>
                      <span className={style.step}>02</span>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      xs={9}
                      md={10}
                      className={"ps-3 ps-xl-0 ps-lg-0"}
                    >
                      <div
                        className={
                          "d-flex flex-wrap justify-content-between align-items-center "
                        }
                      >
                        <Col xl={5} lg={5} xs={12} md={12}>
                          <p className={style.stepTitle}>
                            Skenirajte QR kod ili unesite sedmocifreni broj koji
                            ste dobili SMS porukom.
                          </p>
                        </Col>
                        <Col
                          xl={4}
                          lg={4}
                          xs={12}
                          md={12}
                          className={"pt-xl-0 pt-lg-0 pt-3"}
                        >
                          <div className={"d-flex justify-content-center"}>
                            <QrIcon />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </div>
                </Col>
                <Col xl={12} lg={12} xs={12} md={12} className={"pt-4"}>
                  <div className={style.stepCol}>
                    <Col xl={2} lg={2} xs={3} md={2}>
                      <span className={style.step}>03</span>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      xs={9}
                      md={10}
                      className={"ps-3 ps-xl-0 ps-lg-0"}
                    >
                      <div
                        className={
                          "d-flex flex-wrap justify-content-between align-items-center "
                        }
                      >
                        <Col xl={5} lg={5} xs={12} md={12}>
                          <p className={style.stepTitle}>
                            Potvrdite operaciju unošenjem broja telefona
                            primaoca pošiljke.
                          </p>
                        </Col>
                        <Col
                          xl={4}
                          lg={4}
                          xs={12}
                          md={12}
                          className={"pt-xl-0 pt-lg-0 pt-3"}
                        >
                          <div className={"d-flex justify-content-center"}>
                            <PhoneNumber />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </div>
                </Col>
                <Col xl={12} lg={12} xs={12} md={12} className={"pt-4"}>
                  <div className={style.stepCol}>
                    <Col xl={2} lg={2} xs={3} md={2}>
                      <span className={style.step}>04</span>
                    </Col>
                    <Col
                      xl={10}
                      lg={10}
                      xs={9}
                      md={10}
                      className={"ps-3 ps-xl-0 ps-lg-0"}
                    >
                      <div
                        className={
                          "d-flex flex-wrap justify-content-between align-items-center "
                        }
                      >
                        <Col xl={5} lg={5} xs={12} md={12}>
                          <p className={style.stepTitle}>
                            Ukoliko ste uspješno uradili prethodne korake,
                            preuzmite vaš paket i zatvorite vrata od sandučeta.{" "}
                          </p>
                        </Col>
                        <Col
                          xl={4}
                          lg={4}
                          xs={12}
                          md={12}
                          className={"pt-xl-0 pt-lg-0 pt-3"}
                        >
                          <div className={"d-flex justify-content-center"}>
                            <PacketEE />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </Col>
        <div className={style.icons}>
          <Col xl={2} lg={3} md={6} xs={6} className={"p-4 p-xl-0 p-lg-0"}>
            <div className={style.iconDetails}>
              <Col xl={4} lg={5} md={12} xs={12}>
                <div className={style.icon}>
                  <FastIcon />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12}>
                <p className={style.iconText}>BRZO </p>
              </Col>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6} xs={6} className={"p-4 p-xl-0 p-lg-0"}>
            <div className={style.iconDetails}>
              <Col xl={4} lg={5} md={12} xs={12}>
                <div className={style.icon}>
                  <SecureIcon />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12}>
                <p className={style.iconText}>SIGURNO</p>
              </Col>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6} xs={6} className={"p-4 p-xl-0 p-lg-0"}>
            <div className={style.iconDetails}>
              <Col xl={4} lg={5} md={12} xs={12}>
                <div className={style.icon}>
                  <Secure2Icon />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12}>
                <p className={style.iconText}>POUZDANO</p>
              </Col>
            </div>
          </Col>
          <Col xl={2} lg={3} md={6} xs={6} className={"p-4 p-xl-0 p-lg-0"}>
            <div className={style.iconDetails}>
              <Col xl={4} lg={5} md={12} xs={12}>
                <div className={style.icon}>
                  <TimeSpent />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} xs={12}>
                <p className={style.iconText}>BEZ ČEKANJA</p>
              </Col>
            </div>
          </Col>
        </div>
        <div className={"pt-5"}>
          <p className={style.iconText}>
            Za više informacija možete pozvati naš info broj 1331.
          </p>
        </div>
        <div className={"pt-5"}>
          <ButtonEE>
            <p
              className={style.setButtonText}
              onClick={() => {
                window.open("/terms-of-use", "_blank");
              }}
            >
              Uslovi korištenja paketomata
            </p>
          </ButtonEE>
        </div>
      </div>
    </div>
  );
}
