import LazyLoad from "react-lazyload";
import style from "../video/Video.module.scss";
export function LazyLoadVideo({
  src,
  poster,
}: {
  src: string;
  poster: string;
}) {
  return (
    <LazyLoad height={"auto"} offset={100}>
      <video
        className={style.videoPlayer}
        controls={true}
        autoPlay={true}
        poster={poster}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </LazyLoad>
  );
}
