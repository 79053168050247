import style from "./Location.module.scss";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { MapComponent } from "../map";
import { ButtonEE } from "../button";
import React, { useState } from "react";
import { Questions } from "../questions";

export function Location() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <div className={style.headerCc} id="find">
      <div className={style.locationContainer}>
        <Col xl={12} lg={12} xs={12} md={12}>
          <div
            className={"w-100 d-flex align-items-center justify-content-center"}
          >
            <h2 className={style.locationTitle}>
              Potražite najbliži <span>paketomat</span>
            </h2>
          </div>
        </Col>
        <Col xl={12} lg={12} xs={12} md={12}>
          <div className={style.locationMap}>
            <MapComponent />
          </div>
        </Col>
        {/*<Col xl={12} lg={12} xs={12} md={12} id={"question"}>*/}
        {/*  <div*/}
        {/*    className={"d-flex align-items-center justify-content-center pt-5"}*/}
        {/*    onClick={toggle}*/}
        {/*  >*/}
        {/*    <ButtonEE>*/}
        {/*      <p className={style.questions}>često postavljena pitanja</p>*/}
        {/*    </ButtonEE>*/}
        {/*  </div>*/}
        {/*</Col>*/}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true} size={"xl"}>
        <ModalHeader toggle={toggle}>
          {" "}
          <h2 className={style.questionTitle}>najčešća PITANJA </h2>
        </ModalHeader>
        <ModalBody>
          <Questions />
        </ModalBody>
      </Modal>
    </div>
  );
}
