import style from "./Footer.module.scss";
import { Col, Collapse, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import { Logo } from "../../assets/logo";
import { FbIcon, InstagramIcon, LinkedInIcon } from "../../assets/fb";
import React from "react";
import { Link } from "react-router-dom";

export function Footer({ mustHref }: { mustHref?: boolean }) {
  const onPress = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    block: "center" | "end" | "nearest" | "start" = "center",
  ) => {
    if (!mustHref) {
      e.preventDefault();
      const target = window.document.getElementById(
        e.currentTarget.href.split("#")[1],
      );
      if (target) {
        target.scrollIntoView({ behavior: "smooth", block: block });
      }
    }
  };
  return (
    <div className={style.headerCc}>
      <div className={style.footerContainer}>
        <div className={style.footerRow}>
          <Col xl={4} lg={4} md={12} xs={12}>
            <div className={"pb-4"}>
              <Logo />
            </div>
          </Col>
          <Col xl={8} lg={8} md={12} xs={12} className={"pt-4 pt-xl-0 pt-lg-0"}>
            <Navbar expand={"lg"}>
              <Collapse isOpen={true} navbar>
                <Nav className="ms-auto me-auto" navbar>
                  <NavItem className={style.navItem}>
                    <NavLink
                      className={style.navLink}
                      href={mustHref ? "home" : "#home"}
                      onClick={(e: any) => onPress(e)}
                    >
                      PoČetna
                    </NavLink>
                  </NavItem>
                  <NavItem className={style.navItem}>
                    <NavLink
                      className={style.navLink}
                      onClick={(e: any) => onPress(e)}
                      href={mustHref ? "home#steps" : "#steps"}
                      data-to-scrollspy-id="steps"
                    >
                      KAKO PREUZETI PAKET
                    </NavLink>
                  </NavItem>
                  <NavItem className={style.navItem}>
                    <NavLink
                      className={style.navLink}
                      onClick={(e: any) => onPress(e)}
                      href={mustHref ? "home#dimensions" : "#dimensions"}
                    >
                      VELIČINE PRETINACA
                    </NavLink>
                  </NavItem>
                  <NavItem className={style.navItem}>
                    <NavLink
                      className={style.navLink}
                      onClick={(e: any) => onPress(e)}
                      href={mustHref ? "home#find" : "#find"}
                    >
                      PRONAĐITE PAKETOMAT
                    </NavLink>
                  </NavItem>
                  <NavItem className={style.navItem}>
                    <NavLink href={"/terms-of-use"} className={style.navLink}>
                      USLOVI KORIŠĆENJA
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12}>
            <div className={style.followAsFooter}>
              <Col xl={4} lg={4} xs={12} md={12}>
                <p className={style.textNormal}>
                  Pratite nas na društvenim mrežama
                </p>
                <div className={style.iconsFooter}>
                  <div className={"pe-3"}>
                    {" "}
                    <Link to={"https://www.facebook.com/euroexpress.ba"}>
                      <FbIcon />
                    </Link>
                  </div>
                  <div className={"pe-3"}>
                    {" "}
                    <Link
                      to={"https://www.instagram.com/euroexpressbrzaposta/"}
                    >
                      <InstagramIcon />
                    </Link>
                  </div>
                  <div>
                    {" "}
                    <Link
                      to={
                        "https://www.linkedin.com/company/euroexpress-brza-posta/"
                      }
                    >
                      {" "}
                      <LinkedInIcon />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} xs={12} md={12}>
                <div className={style.infoDetail}>
                  <div className={"ps-3"}>
                    <p className={style.textBoldInfo}>Info Broj</p>
                    <p className={style.textBoldedInfo}>1331</p>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
        <div
          className={`d-flex justify-content-between align-items-center flex-wrap w-100 pt-3 ${style.borderTopFooter}`}
        >
          <p className={style.text}>
            Copyright © EuroExpress d.o.o. - 2024. Sva prava zadržana.
          </p>
          <p className={style.text}>
            Created by{" "}
            <span
              className={style.textBold}
              onClick={() => {
                window.open("https://novamedia.agency", "_blank");
              }}
            >
              NOVA media{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
