export function PlayButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="188"
      height="188"
      viewBox="0 0 188 188"
    >
      <g id="Group_288" data-name="Group 288" transform="translate(-866 -870)">
        <circle
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="94"
          cy="94"
          r="94"
          transform="translate(866 870)"
          fill="#fff"
        />
        <path
          id="Polygon_2"
          data-name="Polygon 2"
          d="M49.681,29.985a20,20,0,0,1,34.637,0L116.671,86a20,20,0,0,1-17.319,30h-64.7A20,20,0,0,1,17.329,86Z"
          transform="translate(1035 897) rotate(90)"
          fill="#da212e"
        />
      </g>
    </svg>
  );
}
