import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { Minus } from "../../assets/Minus";
import { PlusIcon } from "../../assets/plus";
import style from "./Questions.module.scss";

export function Question({
  questionName,
  questionDetail,
}: {
  questionName: string;
  questionDetail: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={style.question}>
      <div className={style.questionName} onClick={toggle}>
        {isOpen ? <Minus /> : <PlusIcon />}
        <p className={style.questionNameText}>{questionName}</p>
      </div>

      <Collapse isOpen={isOpen}>
        <div className={style.questionDetail}>
          <p className={style.questionDetailText}>{questionDetail}</p>
        </div>
      </Collapse>
    </div>
  );
}
