export function CardPayment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48.168"
      height="36.126"
      viewBox="0 0 48.168 36.126"
    >
      <g id="credit-card" transform="translate(0 -3)">
        <circle
          id="Ellipse_6"
          data-name="Ellipse 6"
          cx="3"
          cy="3"
          r="3"
          transform="translate(8.084 25.063)"
        />
        <path
          id="Path_1382"
          data-name="Path 1382"
          d="M38.133,3h-28.1A10.047,10.047,0,0,0,0,13.035V29.091A10.047,10.047,0,0,0,10.035,39.126h28.1A10.047,10.047,0,0,0,48.168,29.091V13.035A10.047,10.047,0,0,0,38.133,3Zm-28.1,4.014h28.1a6.021,6.021,0,0,1,6.021,6.021H4.014a6.021,6.021,0,0,1,6.021-6.021Zm28.1,28.1h-28.1a6.021,6.021,0,0,1-6.021-6.021V17.049h40.14V29.091A6.021,6.021,0,0,1,38.133,35.112Z"
        />
      </g>
    </svg>
  );
}
