import React from "react";
import "./App.scss";
import Home from "./pages/home";
import TermsOfUsePage from "./pages/terms-of-use";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { pdfjs } from "react-pdf";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="terms-of-use" element={<TermsOfUsePage />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
