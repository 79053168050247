export function SecureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        id="badge-check"
        d="M25,50a11.436,11.436,0,0,1-8.767-4.081,10.963,10.963,0,0,1-8.91-3.24A11.448,11.448,0,0,1,4.01,33.592,10.979,10.979,0,0,1,0,25a11.431,11.431,0,0,1,4.083-8.767,10.977,10.977,0,0,1,3.24-8.91A11.4,11.4,0,0,1,16.408,4.01,10.986,10.986,0,0,1,25,0a11.436,11.436,0,0,1,8.767,4.081,10.979,10.979,0,0,1,8.91,3.24,11.448,11.448,0,0,1,3.313,9.088A10.979,10.979,0,0,1,50,25a11.431,11.431,0,0,1-4.083,8.767,10.977,10.977,0,0,1-3.24,8.91,11.466,11.466,0,0,1-9.085,3.313A10.986,10.986,0,0,1,25,50Zm-8.6-8.175a3.91,3.91,0,0,1,3.017,1.408,7.29,7.29,0,0,0,11.163,0,3.921,3.921,0,0,1,3.367-1.4,7.291,7.291,0,0,0,7.892-7.892,3.909,3.909,0,0,1,1.4-3.369,7.29,7.29,0,0,0,0-11.158,3.913,3.913,0,0,1-1.4-3.369,7.291,7.291,0,0,0-7.89-7.892,3.9,3.9,0,0,1-3.369-1.394,7.29,7.29,0,0,0-11.163,0,3.941,3.941,0,0,1-3.367,1.4A7.291,7.291,0,0,0,8.16,16.052a3.909,3.909,0,0,1-1.4,3.369,7.29,7.29,0,0,0,0,11.158,3.913,3.913,0,0,1,1.4,3.369,7.291,7.291,0,0,0,7.89,7.892c.119-.01.235-.015.352-.015ZM26.669,31.531l9.873-9.517a2.084,2.084,0,0,0-2.894-3l-9.9,9.544a2.1,2.1,0,0,1-3-.052L16,24.1a2.083,2.083,0,0,0-2.835,3.052l4.692,4.358a6.248,6.248,0,0,0,4.429,1.831,6.18,6.18,0,0,0,4.383-1.8Z"
      />
    </svg>
  );
}
