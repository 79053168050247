export function FbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="if_facebook_circle_black_107153___1WWDsqkI"
        d="M16.741,28.7h3.6V20h2.4l.318-3H20.338V15.5c0-.782.075-1.2,1.2-1.2h1.5v-3h-2.4c-2.883,0-3.9,1.455-3.9,3.9V17h-1.8v3h1.8ZM19,36A16,16,0,1,1,35,20,16,16,0,0,1,19,36Z"
        transform="translate(-3 -4)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
}

export function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="if_instagram_circle_color_107172___HcjPhAER"
        d="M24.945,16.042h0c.268,0,.536,0,.8,0a.652.652,0,0,0,.625-.649V13.847a.657.657,0,0,0-.659-.662H24.177a.658.658,0,0,0-.659.663q0,.763,0,1.528a.747.747,0,0,0,.039.227.657.657,0,0,0,.645.439ZM20,16.7a3.3,3.3,0,1,0,2.359.991A3.3,3.3,0,0,0,20,16.7Zm-6.375,1.759v7.268a.659.659,0,0,0,.646.644H25.726a.658.658,0,0,0,.648-.646q0-3.607,0-7.215v-.05H24.821a5.091,5.091,0,0,1,.2,2.141,4.972,4.972,0,0,1-.7,2.028,5.057,5.057,0,0,1-6.787,1.785,4.942,4.942,0,0,1-1.965-1.98,5.053,5.053,0,0,1-.394-3.974ZM26.51,28.133c.085-.014.17-.026.253-.044A1.761,1.761,0,0,0,28.1,26.728c.013-.073.024-.146.036-.218V13.49c-.011-.071-.021-.143-.035-.214a1.761,1.761,0,0,0-1.483-1.4.579.579,0,0,1-.091-.016H13.473c-.078.014-.158.025-.235.043a1.755,1.755,0,0,0-1.357,1.475.719.719,0,0,1-.015.091V26.528c.015.082.027.166.045.248a1.761,1.761,0,0,0,1.477,1.344l.1.016ZM20,36A16,16,0,1,1,36,20,16,16,0,0,1,20,36Z"
        transform="translate(-4 -4)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
}
export function LinkedInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="if_linkedin_circle_color_107178___xPvp2bKl"
        d="M28.98,27.54V21.293c0-3.347-1.787-4.9-4.169-4.9a3.594,3.594,0,0,0-3.263,1.8V16.645H17.926c.048,1.022,0,10.894,0,10.894h3.621V21.455a2.469,2.469,0,0,1,.119-.883,1.981,1.981,0,0,1,1.857-1.324c1.311,0,1.835,1,1.835,2.463V27.54ZM14.111,15.158a1.888,1.888,0,1,0,.024-3.765,1.888,1.888,0,1,0-.047,3.765ZM20,36A16,16,0,1,1,36,20,16,16,0,0,1,20,36Zm-4.078-8.46v-10.9H12.3v10.9Z"
        transform="translate(-4 -4)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
}
