export function PhoneNumber() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="209.306"
      height="30.118"
      viewBox="0 0 209.306 30.118"
    >
      <rect
        id="Rectangle_1303"
        data-name="Rectangle 1303"
        width="209.306"
        height="30.118"
        rx="15.059"
        fill="#c80820"
      />
      <text
        id="_06_"
        data-name="06* *** ***"
        transform="translate(58 22)"
        fill="#fff"
        font-size="18"
        font-family="Poppins-Regular, Poppins"
      >
        <tspan x="0" y="0">
          06* *** ***
        </tspan>
      </text>
    </svg>
  );
}
