export function FastIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.927"
      height="45.766"
      viewBox="0 0 49.927 45.766"
    >
      <path
        id="shipping-fast"
        d="M39.525,9.321H35.2a10.391,10.391,0,0,0-7.643-8.255,2.079,2.079,0,1,0-1.036,4.027A6.238,6.238,0,0,1,31.2,11.139V34.284H8.321a4.165,4.165,0,0,1-4.161-4.161V21.8h5.2a2.08,2.08,0,1,0,0-4.161h-5.2A4.165,4.165,0,0,0,0,21.8v8.321A8.336,8.336,0,0,0,6.355,38.21a7.281,7.281,0,1,0,14.371.235H29.2a7.2,7.2,0,0,0-.077,1.04A7.281,7.281,0,1,0,43.571,38.21a8.336,8.336,0,0,0,6.355-8.086v-10.4a10.413,10.413,0,0,0-10.4-10.4Zm6.241,10.4V21.8h-10.4V13.482h4.161A6.248,6.248,0,0,1,45.766,19.722ZM16.642,39.485a3.12,3.12,0,1,1-6.241,0,2.944,2.944,0,0,1,.189-1.04h5.864a2.944,2.944,0,0,1,.189,1.04ZM36.4,42.606a3.124,3.124,0,0,1-3.12-3.12,2.944,2.944,0,0,1,.189-1.04h5.864a2.944,2.944,0,0,1,.189,1.04,3.124,3.124,0,0,1-3.12,3.12Zm5.2-8.321H35.365V25.963h10.4v4.161A4.165,4.165,0,0,1,41.606,34.284ZM0,3.08A2.08,2.08,0,0,1,2.08,1H19.043a2.08,2.08,0,1,1,0,4.161H2.08A2.08,2.08,0,0,1,0,3.08ZM0,11.4a2.08,2.08,0,0,1,2.08-2.08h12.8a2.08,2.08,0,1,1,0,4.161H2.08A2.08,2.08,0,0,1,0,11.4Z"
        transform="translate(0 -1)"
      />
    </svg>
  );
}
