import style from "../Header.module.scss";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavItem,
  NavLink,
} from "reactstrap";
import { Logo } from "../../../assets/logo";
import { ButtonEE } from "../../button";
import React, { useEffect, useRef, useState } from "react";
import { PhoneIcon } from "../../../assets/phoneIcon";
import { CiMenuBurger } from "react-icons/ci";
import { MdOutlineClose } from "react-icons/md";

export function NavbarMenu({ mustHref }: { mustHref?: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);

  const toggle = () => setIsOpen(!isOpen);

  const onPress = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    block: "center" | "end" | "nearest" | "start" = "center",
  ) => {
    if (!mustHref) {
      e.preventDefault();
      const target = window.document.getElementById(
        e.currentTarget.href.split("#")[1],
      );
      if (target) {
        target.scrollIntoView({ behavior: "smooth", block: block });
      }
    }
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className={`${style.headerCcNav} sticky-top`} ref={navbarRef}>
      <div className={style.headerContainerNav}>
        <Navbar sticky={"top"} expand={"lg"} className={style.navBarNav}>
          <NavbarBrand href="/" className={style.logoNav}>
            <Logo />
          </NavbarBrand>
          {isOpen ? (
            <MdOutlineClose onClick={toggle} className={style.togglerNav} />
          ) : (
            <CiMenuBurger onClick={toggle} className={style.togglerNav} />
          )}

          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-auto me-auto pb-4 pb-xl-0 pb-lg-0 " navbar>
              <NavItem className={style.navItem}>
                <NavLink
                  href={mustHref ? "home" : "#home"}
                  onClick={(e: any) => onPress(e)}
                  className={style.navLinkNav}
                >
                  PoČetna
                </NavLink>
              </NavItem>
              <NavItem className={style.navItem}>
                <NavLink
                  className={style.navLinkNav}
                  onClick={(e: any) => onPress(e)}
                  href={mustHref ? "home#steps" : "#steps"}
                  data-to-scrollspy-id="steps"
                >
                  KAKO PREUZETI PAKET
                </NavLink>
              </NavItem>
              <NavItem
                className={style.navItem}
                data-to-scrollspy-id="dimensions"
              >
                <NavLink
                  className={style.navLinkNav}
                  onClick={(e: any) => onPress(e)}
                  href={mustHref ? "home#dimensions" : "#dimensions"}
                >
                  VELIČINE PRETINACA
                </NavLink>
              </NavItem>
              <NavItem className={style.navItem} data-to-scrollspy-id="find">
                <NavLink
                  className={style.navLinkNav}
                  onClick={(e: any) => onPress(e)}
                  href={mustHref ? "home#find" : "#find"}
                >
                  PRONAĐITE PAKETOMAT
                </NavLink>
              </NavItem>
              <NavItem className={style.navItem}>
                <NavLink href={"/terms-of-use"} className={style.navLinkNav}>
                  USLOVI KORIŠĆENJA
                </NavLink>
              </NavItem>
            </Nav>
            <NavbarText
              className={"d-flex justify-content-center align-items-center"}
            >
              <ButtonEE>
                <PhoneIcon />
                <div className={style.infoDetail}>
                  <div className={"ps-3"}>
                    <p className={style.textBoldInfo}>Info Broj</p>
                    <p className={style.textBoldedInfo}>1331</p>
                  </div>
                </div>
              </ButtonEE>
            </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
}
