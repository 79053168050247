export function PlusIcon() {
  return (
    <svg
      id="plus"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        id="Path_1383"
        data-name="Path 1383"
        d="M32.813,15.313H19.688V2.188a2.188,2.188,0,0,0-4.375,0V15.313H2.188a2.188,2.188,0,0,0,0,4.375H15.313V32.813a2.188,2.188,0,0,0,4.375,0V19.688H32.813a2.188,2.188,0,0,0,0-4.375Z"
      />
    </svg>
  );
}
