import style from "./Video.module.scss";
import { PlayButton } from "../../assets/play-button";
import { Button, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { TimeSpent } from "../../assets/timeSpent";
import { Checkout } from "../../assets/checkout";
import { CardPayment } from "../../assets/cardPayment";
import { useState } from "react";
import { LazyLoadVideo } from "../LazyLoadVideo";

export function Video() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <div className={style.headerCc} id={"video"}>
      <div className={style.videoContainer}>
        <div className={style.video}>
          <div className={style.playButton} onClick={toggle}>
            <PlayButton />
          </div>
        </div>
      </div>
      <div className={style.icons}>
        <Col
          xl={4}
          lg={4}
          md={4}
          xs={4}
          className={"pt-4 pt-xl-0 pt-md-0 pt-lg-0"}
        >
          <div className={style.iconDetails}>
            <Col xl={3} lg={4} md={12} xs={12}>
              <div className={style.icon}>
                <TimeSpent />
              </div>
            </Col>
            <Col xl={9} lg={8} md={12} xs={12}>
              <p className={style.iconText}>Ušteda vremena </p>
            </Col>
          </div>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={4}
          xs={4}
          className={"pt-4 pt-xl-0 pt-md-0 pt-lg-0"}
        >
          <div className={style.iconDetails}>
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className={style.icon}>
                <Checkout />
              </div>
            </Col>
            <Col xl={9} lg={8} md={12} xs={12}>
              <p className={style.iconText}>Preuzimanje 24/7</p>
            </Col>
          </div>
        </Col>
        <Col
          xl={3}
          lg={4}
          md={4}
          xs={4}
          className={"pt-4 pt-xl-0 pt-md-0 pt-lg-0"}
        >
          <div className={style.iconDetails}>
            <Col xl={4} lg={4} md={12} xs={12}>
              <div className={style.icon}>
                <CardPayment />
              </div>
            </Col>
            <Col xl={8} lg={8} md={12} xs={12}>
              <p className={style.iconText}>
                Uskoro i mogućnost <br /> kartičnog plaćanja
              </p>
            </Col>
          </div>
        </Col>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true} size={"lg"}>
        <ModalBody>
          <LazyLoadVideo
            src="video.mp4"
            poster="https://www.w3schools.com/html/mov_bbb.jpg"
          />
        </ModalBody>
        <ModalFooter>
          {" "}
          <Button color="secondary" onClick={toggle}>
            Zatvori
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
