import style from "./WidthPacket.module.scss";
import { Col } from "reactstrap";

export function WidthPacket() {
  return (
    <div className={style.headerCc} id="dimensions">
      <div className={style.packetWidthContainer}>
        <Col xl={12} lg={12} xs={12} md={12}>
          <div
            className={"w-100 d-flex align-items-center justify-content-center"}
          >
            <h2 className={style.packetWidthTitle}>
              DIMENZIJE <span>PRETINACA</span>
            </h2>
          </div>
        </Col>
        <Col xl={12} lg={12} xs={12} md={12}>
          <div className={"w-100 d-flex  flex-wrap align-items-center"}>
            <Col
              xl={4}
              lg={4}
              xs={12}
              md={12}
              className={style.packetWidthDetail}
            >
              <div
                className={`${style.showDesktop} ${style.packetWidthDetails} ${style.borderTop}`}
              >
                <p className={style.packetWidthDetailTitle}>Mali pretinac</p>
              </div>
              <div
                className={`${style.packetWidthImage} ${style.imageS}`}
              ></div>
              <div
                className={`${style.showMobile} ${style.packetWidthDetails} ${style.borderBottom}`}
              >
                <p className={style.packetWidthDetailTitle}>Mali pretinac</p>
                <p className={style.packetWidthDetailSubTitle}>
                  š:400MM D:495MM V:450MM
                </p>
              </div>
            </Col>
            <Col
              xl={4}
              lg={4}
              xs={12}
              md={12}
              className={style.packetWidthDetail}
            >
              <div
                className={`${style.packetWidthImage} ${style.imageM}`}
              ></div>
              <div
                className={`d-flex ${style.packetWidthDetails} ${style.borderBottom}`}
              >
                <p className={style.packetWidthDetailTitle}>Srednji pretinac</p>
              </div>
            </Col>
            <Col
              xl={4}
              lg={4}
              xs={12}
              md={12}
              className={style.packetWidthDetail}
            >
              <div
                className={`${style.showDesktop} ${style.packetWidthDetails} ${style.borderTop}`}
              >
                <p className={style.packetWidthDetailTitle}>Veliki pretinac</p>
              </div>
              <div
                className={`${style.packetWidthImage} ${style.imageL}`}
              ></div>
              <div
                className={`${style.showMobile} ${style.packetWidthDetails} ${style.borderBottom}`}
              >
                <p className={style.packetWidthDetailTitle}>Veliki pretinac</p>
              </div>
            </Col>
          </div>
        </Col>
      </div>
    </div>
  );
}
