export function LocationPoint() {
  return (
    <svg
      id="marker"
      xmlns="http://www.w3.org/2000/svg"
      width="13.514"
      height="16.129"
      viewBox="0 0 13.514 16.129"
    >
      <path
        id="Path_318"
        data-name="Path 318"
        d="M10.688,6a2.688,2.688,0,1,0,2.688,2.688A2.688,2.688,0,0,0,10.688,6Zm0,4.032a1.344,1.344,0,1,1,1.344-1.344A1.344,1.344,0,0,1,10.688,10.032Z"
        transform="translate(-3.931 -1.967)"
        fill="#da212e"
      />
      <path
        id="Path_319"
        data-name="Path 319"
        d="M8.7,16.127a3.542,3.542,0,0,1-2.9-1.478c-2.561-3.533-3.86-6.188-3.86-7.894a6.757,6.757,0,1,1,13.514,0c0,1.706-1.3,4.361-3.86,7.894A3.542,3.542,0,0,1,8.7,16.127Zm0-14.662A5.3,5.3,0,0,0,3.411,6.756c0,1.351,1.272,3.849,3.581,7.033a2.113,2.113,0,0,0,3.421,0c2.309-3.185,3.581-5.682,3.581-7.033A5.3,5.3,0,0,0,8.7,1.465Z"
        transform="translate(-1.945 0.002)"
        fill="#da212e"
      />
    </svg>
  );
}
export function LocationTime() {
  return (
    <svg
      id="time-fast"
      xmlns="http://www.w3.org/2000/svg"
      width="15.928"
      height="15.927"
      viewBox="0 0 15.928 15.927"
    >
      <path
        id="Path_313"
        data-name="Path 313"
        d="M5.973,23.327H.664A.664.664,0,1,1,.664,22H5.973a.664.664,0,0,1,0,1.327Z"
        transform="translate(0 -7.4)"
        fill="#da212e"
      />
      <path
        id="Path_314"
        data-name="Path 314"
        d="M4.645,19.327H.664A.664.664,0,1,1,.664,18H4.645a.664.664,0,1,1,0,1.327Z"
        transform="translate(0 -6.054)"
        fill="#da212e"
      />
      <path
        id="Path_315"
        data-name="Path 315"
        d="M3.318,15.327H.664A.664.664,0,1,1,.664,14H3.318a.664.664,0,1,1,0,1.327Z"
        transform="translate(0 -4.707)"
        fill="#da212e"
      />
      <path
        id="Path_316"
        data-name="Path 316"
        d="M8.642,15.891a.664.664,0,1,1-.059-1.327A6.635,6.635,0,1,0,1.37,7.355.664.664,0,0,1,.048,7.236,7.964,7.964,0,1,1,8.7,15.888C8.682,15.89,8.662,15.891,8.642,15.891Z"
        transform="translate(-0.015 0.007)"
        fill="#da212e"
      />
      <path
        id="Path_317"
        data-name="Path 317"
        d="M11.664,6A.664.664,0,0,0,11,6.664V9.982a.664.664,0,0,0,.194.469l1.991,1.991a.664.664,0,1,0,.938-.938l-1.8-1.8V6.664A.664.664,0,0,0,11.664,6Z"
        transform="translate(-3.7 -2.016)"
        fill="#da212e"
      />
    </svg>
  );
}
