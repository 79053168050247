import { Col } from "reactstrap";
import style from "./Header.module.scss";
import { ArrowDown } from "../../assets/arrow-down";
import React from "react";

export function Header() {
  const onPress = (
    e: any,
    block: "center" | "end" | "nearest" | "start" = "center",
  ) => {
    e.preventDefault();
    const target = window.document.getElementById("video");
    console.log(target);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <div className={style.headerCc}>
      <div className={style.headerContainer}>
        <div
          className={"d-flex flex-wrap justify-content-between pt-5 "}
          id={"home"}
        >
          <Col
            xl={{ size: 6, offset: 1 }}
            lg={12}
            md={12}
            xs={12}
            className={"position-relative"}
          >
            <div className={"d-flex flex-column"}>
              <h2 className={style.ourServiceTitle}>PAKETOMAT</h2>
              <img
                alt={"slogan"}
                className={style.ourServiceSubTitle}
                src={"white-slogan.svg"}
              />
            </div>
          </Col>
          <Col
            xl={5}
            lg={12}
            md={12}
            xs={12}
            className={style.detailServiceText}
          >
            <p className={style.ourServiceDetail}>
              Prvi smo u našoj zemlji uveli mogućnost prijema pošiljki putem
              paketomata.{" "}
            </p>
            <span
              className={style.iconArrow}
              onClick={(event) => onPress(event)}
            >
              {" "}
              <ArrowDown />
            </span>
          </Col>
        </div>
      </div>
    </div>
  );
}
