import style from "./Questions.module.scss";
import { Col, Row } from "reactstrap";
import React from "react";
import { Question } from "./question";

export function Questions() {
  return (
    <div className={style.headerCc}>
      <div className={style.questionContainer}>
        <Col xl={12} lg={12} xs={12} md={12} className={style.questionsDetail}>
          <Row className={"align-items-center"}>
            <Col xl={5} lg={5} xs={12} md={12}>
              <div className={style.questionImage}></div>
            </Col>
            <Col xl={7} lg={7} xs={12} md={12} className={style.questions}>
              <Question
                questionName={"Prvo pitanje koje će biti ovde napisano?"}
                questionDetail={"Prvo pitanje koje će biti ovde napisano?"}
              />
              <Question
                questionName={"Drugo pitanje koje će biti ovde napisano?"}
                questionDetail={"Prvo pitanje koje će biti ovde napisano?"}
              />
              <Question
                questionName={"Treće pitanje koje će biti ovde napisano?"}
                questionDetail={"Prvo pitanje koje će biti ovde napisano?"}
              />
              <Question
                questionName={"Četvrto pitanje koje će biti ovde napisano?"}
                questionDetail={
                  "Naša kompanija je lider na tržištu, prepoznata kao brend broj jedan¹ u našoj zemlji u poslovima preuzimanja i dostave pošiljaka. Korisnici nas vide kao najpoželjnijeg partnera koji im nudi kompletna rješenja u oblasti poslovanja brze pošte."
                }
              />
              <Question
                questionName={"Peto pitanje koje će biti ovde napisano?"}
                questionDetail={"Peto pitanje koje će biti ovde napisano?"}
              />
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
}
