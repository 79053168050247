export function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <g id="Group_283" data-name="Group 283" transform="translate(-1652 -295)">
        <g
          id="Ellipse_5"
          data-name="Ellipse 5"
          transform="translate(1652 295)"
          fill="none"
          stroke="#fff"
          stroke-width="3"
        >
          <circle cx="32" cy="32" r="32" stroke="none" />
          <circle cx="32" cy="32" r="30.5" fill="none" />
        </g>
        <path
          id="arrow-down"
          d="M21.287,22.406a1.259,1.259,0,0,0-1.788,0l-4.572,4.572V1.259A1.259,1.259,0,0,0,13.668,0h0a1.259,1.259,0,0,0-1.259,1.259V26.965L7.849,22.406a1.259,1.259,0,1,0-1.738,1.776l4.937,4.937a3.778,3.778,0,0,0,5.34,0l4.937-4.937A1.259,1.259,0,0,0,21.287,22.406Z"
          transform="translate(1671.367 312.067)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
