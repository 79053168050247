export function Checkout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48.396"
      height="48.386"
      viewBox="0 0 48.396 48.386"
    >
      <path
        id="time-twenty-four"
        d="M29.44,42.944c-.6.456-1.248.936-1.794,1.411h4.617a2.016,2.016,0,0,1,0,4.032H24.2a2.016,2.016,0,0,1-2.016-2.016c0-3.024,2.571-4.952,4.839-6.653,1.512-1.133,3.226-2.419,3.226-3.428a2.016,2.016,0,1,0-4.032,0,2.016,2.016,0,1,1-4.032,0,6.049,6.049,0,0,1,12.1,0C34.279,39.314,31.708,41.242,29.44,42.944Zm16.936-12.7a2.016,2.016,0,0,0-2.016,2.016v6.049H42.344a2.016,2.016,0,0,1-2.016-2.016V32.258a2.016,2.016,0,1,0-4.032,0V36.29a6.049,6.049,0,0,0,6.049,6.049H44.36v4.032a2.016,2.016,0,1,0,4.032,0V32.258a2.016,2.016,0,0,0-2.016-2.016ZM26.214,24.193V14.112a2.016,2.016,0,1,0-4.032,0v8.065H16.133a2.016,2.016,0,0,0,0,4.032H24.2A2.016,2.016,0,0,0,26.214,24.193ZM46.376,4.031A2.016,2.016,0,0,0,44.36,6.047v4.786A24.195,24.195,0,1,0,15.4,46.738a2.047,2.047,0,0,0,.734.137,2.016,2.016,0,0,0,.734-3.9A20.162,20.162,0,1,1,41.61,14.112H36.3a2.016,2.016,0,0,0,0,4.032h6.049A6.049,6.049,0,0,0,48.392,12.1V6.047a2.016,2.016,0,0,0-2.016-2.016Z"
        transform="translate(0.003 -0.002)"
      />
    </svg>
  );
}
