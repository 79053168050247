export function Secure2Icon() {
  return (
    <svg
      id="shield-check"
      xmlns="http://www.w3.org/2000/svg"
      width="41.804"
      height="50.165"
      viewBox="0 0 41.804 50.165"
    >
      <path
        id="Path_320"
        data-name="Path 320"
        d="M36.657,4.473,23.562.107a2.09,2.09,0,0,0-1.321,0L9.146,4.473A10.436,10.436,0,0,0,2,14.387v10.7c0,15.808,19.23,24.539,20.053,24.9a2.09,2.09,0,0,0,1.7,0c.824-.364,20.053-9.094,20.053-24.9v-10.7a10.436,10.436,0,0,0-7.146-9.914Zm2.966,20.609c0,11.4-13.208,18.881-16.722,20.67C19.384,43.97,6.18,36.514,6.18,25.083v-10.7a6.27,6.27,0,0,1,4.289-5.949L22.9,4.294,35.334,8.438a6.271,6.271,0,0,1,4.289,5.949Z"
        transform="translate(-2 0)"
      />
      <path
        id="Path_321"
        data-name="Path 321"
        d="M24.207,8.62,15.453,17.4l-4.69-4.891a2.09,2.09,0,1,0-3.012,2.9l4.82,5.016a3.913,3.913,0,0,0,2.811,1.254h.069a3.915,3.915,0,0,0,2.79-1.156l8.929-8.929a2.1,2.1,0,1,0-2.964-2.97Z"
        transform="translate(3.593 8.729)"
      />
    </svg>
  );
}
